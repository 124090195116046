/* eslint-disable no-console */
/* eslint-disable no-var */
/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { FC, useState, useEffect } from "react"
import { graphql, useStaticQuery } from "gatsby"
import { Link } from "@components/common/LangLink"
import Img from "gatsby-image"
import LangContext from "@contexts/lang"
import { cta_styles, SanityNavbar } from "@utils/types"
import s from "./Header.module.scss"
import HeaderOption from "./HeaderOption"
import { CALENDLY_URL, LOG_IN_URL } from "@utils/constants"
import LanguageButton from "./LanguageButton"
import { openPopupWidget } from "react-calendly"
import useMounted from "@hooks/useMounted"
import { BE_URL, DEFAULT_COUNTRY_CODE } from "@utils/constants"
import axios from "axios"
import useReadCookie from "@hooks/useReadCookie"
import { v4 as uuidv4 } from "uuid"
import publicIp from "public-ip"
import trackFacebookEvent from "@utils/trackFacebookEvent"
import Banner from "../Banner/Banner"

const Header: FC<{}> = ({}) => {
  const hasMounted = useMounted()
  const { lang } = React.useContext(LangContext)
  const [chosenLink, setChosenLink] = useState(-1)
  const [utmText, setUtmText] = useState(null)
  const {
    navbar: { mainIcon, links },
  } = useStaticQuery<{ navbar: SanityNavbar }>(query)
  const { countryCode } = React.useContext(LangContext)

  useEffect(() => {
    trackFacebookEvent("PageView", {
      country: countryCode || DEFAULT_COUNTRY_CODE,
    })
    switch (window.location.pathname.split("/")[2]) {
      case "features":
        return setChosenLink(0)
      case "pricing":
        return setChosenLink(1)
      case "community":
        return setChosenLink(2)
      default:
        return setChosenLink(-1)
    }
  }, [chosenLink])

  if (typeof window !== "undefined") {
    window.onscroll = function() {
      addShadow()
    }
  }
  if (typeof document !== "undefined") {
    var header = document.querySelector("header")
  }
  function addShadow() {
    if (window.pageYOffset > 90) {
      header?.classList.add("shadow")
    } else {
      header?.classList.remove("shadow")
    }
  }

  const logIn = {
    label: {
      en: "Login",
      ar: "تسجيل الدخول",
    },
    href: `${LOG_IN_URL}?lang=${lang}`,
    style: cta_styles.link,
    target: "_blank",
  }

  const careers = {
    href: "https://careers.zyda.com/?lng=en",
    label: { en: 'Careers', ar: 'وظائف' },
    style: cta_styles.button,
    target: "_blank"
  }

  useEffect(() => {
    let utm = /(utm_source=.*)|(utm_medium=.*)|(utm_campaign=.*)/gi.exec(
      window.location.href
    )
    if (utm) {
      setUtmText(utm[0])
    }
    document
      .getElementById("book-demo-button")
      .addEventListener("click", function() {
        trackFacebookEvent("Schedule", {
          country: countryCode || DEFAULT_COUNTRY_CODE,
        })
      })
    let intercom = document.querySelector(".intercom-lightweight-app-launcher")
    if (intercom) {
      intercom.addEventListener("click", function() {
        trackFacebookEvent("Contact", {
          country: countryCode || DEFAULT_COUNTRY_CODE,
        })
      })
    }
  }, [])
  return (
    <header className={s.header}>
      <div className={s.headerContainer}>
        <div className={s.logoContainer} onClick={() => setChosenLink(-1)}>
          <Link to={utmText ? "/" + "?" + utmText : "/"}>
            {hasMounted && mainIcon && lang && (
              <Img className={s.mainLogo} fluid={mainIcon[lang].asset?.fluid} />
            )}
          </Link>
        </div>
        {links.length && (
          <div className={s.links}>
            {links.map((link, i) => (
              <div key={i} onClick={() => setChosenLink(i)}>
                <HeaderOption
                  utmText={utmText}
                  className={`${s.link} ${chosenLink === i &&
                    s.linkChosen} pageLink`}
                  option={link}
                />
              </div>
            ))}
            <div>
              <HeaderOption
                utmText={utmText}
                className={`${s.link} pageLink`}
                option={careers}
              />
            </div>
          </div>
        )}
        <div className={s.rightSide}>
          <LanguageButton />
          <HeaderOption
            className={`${s.link} ${s.single} pageLink`}
            option={logIn}
          />
          <a
            className="pageLink"
            target="_blank"
            href="https://calendly.com/d/cm6-9js-nh5/increase-revenue-with-online-ordering"
          >
            <button
              className={`demoGoogleTag ${s.demoButton} `}
              id="book-demo-button"
            >
              {lang === "en" ? "Book a Demo" : "حجز موعد"}
            </button>
          </a>
        </div>
      </div>
    </header>
  )
}

export default Header

const query = graphql`
  {
    navbar: sanityNavbar {
      mainIcon {
        en {
          asset {
            fluid {
              ...GatsbySanityImageFluid
            }
          }
        }
        ar {
          asset {
            fluid {
              ...GatsbySanityImageFluid
            }
          }
        }
      }
      links {
        label {
          en
          ar
        }
        href
        style
      }
    }
  }
`
