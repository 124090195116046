export default function useSearchParams(location) {
  function getSearchParams() {
    const search = location.search
    const value = search
      .replace("?", "")
      .split("&")
      .reduce((s, e) => {
        const [k, v] = e.split("=")
        return Object.assign({}, s, { [k]: v })
      }, {})
    return value
  }

  return getSearchParams()
}
