module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-google-tagmanager/gatsby-browser.js'),
      options: {"plugins":[],"id":"GTM-NW24FRS","includeInDevelopment":false,"defaultDataLayer":null,"routeChangeEventName":"gatsby-route-change"},
    },{
      plugin: require('../node_modules/gatsby-plugin-intercom-spa/gatsby-browser.js'),
      options: {"plugins":[],"app_id":"mcr6jziq","include_in_development":true,"delay_timeout":0},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Zyda","short_name":"Zyda","icon":"src/images/zyda_icon.png","start_url":"/","background_color":"#74767a","theme_color":"#742fc4","display":"standalone","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"b2aa2435253768229111fcafbe435971"},
    },{
      plugin: require('../node_modules/gatsby-plugin-intl/gatsby-browser.js'),
      options: {"plugins":[],"path":"/home/runner/work/zyda-www-v2/zyda-www-v2/web/src/intl","languages":["ar","en"],"defaultLanguage":"ar"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
