import { DEFAULT_COUNTRY_CODE } from "@utils/constants"
import React from "react"
import { lang } from "../utils/types"

export default React.createContext<{
  lang: lang
  setLang(newLang: any): any
  countryCode: string
  setCountryCode(newCountry: string): any
}>({
  lang: lang.ar,
  setLang: newLang => {},
  countryCode: DEFAULT_COUNTRY_CODE,
  setCountryCode: setCountryCode => {},
})
