import React from "react"
import Img from "gatsby-image"
import { SanitySocialLink } from "@utils/types"

const SocialLink: React.FC<{ socialLink: SanitySocialLink }> = ({
  socialLink,
}) => {
  if (!socialLink.image || !socialLink.link) return <></>
  
  return (
    <a href={socialLink.link} referrerPolicy="no-referrer" target="_blank">
      <Img fixed={socialLink.image?.asset?.fixed} alt={socialLink.link} />
    </a>
  )
}

export default SocialLink
