import { useState, useEffect } from "react"

export default () => {
  const [hasMounted, setHasMounted] = useState(false)

  useEffect(() => {
    setHasMounted(true)
  })

  return hasMounted
}
