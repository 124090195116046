import React from "react"
import CheckIcon from "@images/icons/check.svg"
import LangContext from "@contexts/lang"
import { SanityPerk } from "@utils/types"

const Perk: React.FC<{
  perk: SanityPerk
  clean?: boolean
  className?: string
}> = ({ perk, clean, className }) => {
  const { lang } = React.useContext(LangContext)

  if (!perk || !perk?.title) {
    return <></>
  }

  return (
    <div className={className || ""}>
      {!clean && <CheckIcon />}
      <span style={{ marginLeft: 12 }}>{perk?.title[lang]}</span>
    </div>
  )
}

export default Perk
