import { v4 as uuidv4 } from "uuid"
import publicIp from "public-ip"
import useReadCookie from "@hooks/useReadCookie"
import { BE_URL, PIXEL_ID } from "@utils/constants"
import axios from "axios"

const trackFacebookEvent = (eventName, userParams) => {
  return trackEvents(eventName, userParams)
}

const getSharedObject = async () => {
  const ipAddress = await publicIp.v4({
    fallbackUrls: ["https://ifconfig.co/ip"],
  })
  const eventId = uuidv4()
  const browserInfo = {
    fbp: useReadCookie("_fbp"),
    fbc: useReadCookie("_fbc"),
    client_ip_address: ipAddress,
    client_user_agent: window?.navigator?.userAgent,
  }
  return {
    eventId: eventId,
    browserInfo: browserInfo,
  }
}

const trackCapiEvents = async (sharedObject, eventName, userParams) => {
  const browserInfo = sharedObject.browserInfo
  const eventId = sharedObject.eventId
  let capiPayload = {
    ...browserInfo,
    event_id: eventId,
    country_code: userParams?.country,
    email: userParams?.email,
    event_time: Math.floor(Date.now() / 1000),
    event_source_url: window?.location?.href,
    event_name: eventName,
    first_name: userParams?.first_name,
    last_name: userParams?.last_name,
    phone: userParams?.phone,
  }
  const obj = Object.entries(capiPayload).reduce(
    (a, [k, v]) => (v ? ((a[k] = v), a) : a),
    {}
  )
  await axios.post(`${BE_URL}/track_website_event`, obj)
}

const trackPixelEvents = (sharedObject, eventName, userParams) => {
  const browserInfo = sharedObject.browserInfo
  const eventId = sharedObject.eventId
  let pixelPayload = {
    ...browserInfo,
    em: userParams?.email,
    country: userParams?.country,
    fn: userParams?.first_name,
    ln: userParams?.last_name,
    ph: userParams?.phone,
  }
  const obj = Object.entries(pixelPayload).reduce(
    (a, [k, v]) => (v ? ((a[k] = v), a) : a),
    {}
  )
  window.fbq.disablePushState = true
  window.fbq.allowDuplicatePageViews = true
  window.fbq("init", PIXEL_ID, obj)
  window.fbq("track", eventName, {}, { eventID: eventId })
}

const initPixel = () => {
  let script2 = document.createElement("noscript")
  let img = document.createElement("img")
  img.src = `https://www.facebook.com/tr?id=${PIXEL_ID}&noscript=1`
  img.style.width = "1"
  img.style.height = "1"
  img.style.display = "none"
  script2.appendChild(img)
  document.getElementsByTagName("head")[0].appendChild(script2)
  let pixelScript = document.createElement("script")
  pixelScript.textContent = `!function(f,b,e,v,n,t,s){if(f.fbq)return;n=f.fbq=function(){n.callMethod?
        n.callMethod.apply(n,arguments):n.queue.push(arguments)};if(!f._fbq)f._fbq=n;
        n.push=n;n.loaded=!0;n.version='2.0';n.queue=[];t=b.createElement(e);t.async=!0;
        t.src=v;s=b.getElementsByTagName(e)[0];s.parentNode.insertBefore(t,s)}(window,
        document,'script','https://connect.facebook.net/en_US/fbevents.js');
        fbq.disablePushState = true;fbq.allowDuplicatePageViews = true
        fbq('init', ${PIXEL_ID});`
  document.getElementsByTagName("head")[0].appendChild(pixelScript)
}

const trackEvents = async (eventName, userParams) => {
  if (window && !window.fbq) {
    initPixel()
  }
  if (window && window.fbq != null) {
    const sharedObject = await getSharedObject()
    trackCapiEvents(sharedObject, eventName, userParams)
    trackPixelEvents(sharedObject, eventName, userParams)
  }
}

export default trackFacebookEvent
