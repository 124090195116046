import React, { useState, useEffect } from "react"
import { navigate } from "gatsby-plugin-intl"
import axios from "axios"
import { SanityRegistrationForm } from "@utils/types"
import LangContext from "@contexts/lang"
import s from "./FreeTrialForm.module.scss"
import Perk from "@components/globals/perks"
import { BE_URL, DEFAULT_COUNTRY_CODE } from "@utils/constants"
import trackFacebookEvent from "@utils/trackFacebookEvent"


const emailPlaceholder = {
  en: "Your email address",
  ar: "ادخل البريد الالكتروني",
}

const FreeTrialForm: React.FC<{
  registrationForm: SanityRegistrationForm
  addLead?: boolean
}> = ({ registrationForm, addLead = false }) => {
  const { lang, countryCode } = React.useContext(LangContext)
  const [email, setEmail] = React.useState("")
  const [loading, setLoading] = React.useState(false)
  const { ctaLink, ctaText, perks } = registrationForm
  const [utmText, setUtmText] = useState("")

  useEffect(() => {
    let utm = /(utm_source=.*)|(utm_medium=.*)|(utm_campaign=.*)/gi.exec(
      window.location.href
    )
    if (utm) {
      setUtmText(utm[0])
    }
  }, [])

  const handleSubmit = async e => {
    e.preventDefault()
    if (addLead) {
      setLoading(true)
      const language = lang === "ar" ? "Arabic" : "English"
      await axios.post(`${BE_URL}/mailing_lists/add_contact`, {
        email,
        list: "leads",
        tags: [countryCode, language, "Zyda website"],
      })
      trackFacebookEvent("Subscribe", {country: countryCode || DEFAULT_COUNTRY_CODE, email: email})
      setLoading(false)
    }

    navigate(ctaLink + "?" + utmText, { state: { email } })
  }

  return (
    <div className={`${s.formContainer} ${lang === "ar" && s.inverted}`}>
      <form className={s.form} onSubmit={handleSubmit}>
        <input
          id="email"
          name="email"
          placeholder={emailPlaceholder[lang]}
          className={s.input}
          value={email}
          required
          onChange={e => setEmail(e.target.value)}
        />
        {ctaText && (
          <button
            className={s.button}
            type="submit"
            disabled={loading}
            id="email-submit-button"
          >
            {ctaText[lang]}
          </button>
        )}
      </form>
      {perks?.length > 0 && (
        <div className={s.perksContainer}>
          {perks.map((perk, i) => (
            <div className={s.perkWrapper} key={i}>
              <Perk perk={perk} />
            </div>
          ))}
        </div>
      )}
    </div>
  )
}

export default FreeTrialForm
