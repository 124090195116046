let ar = require('../intl/ar.json');
let en = require('../intl/en.json');

const texts = { ar, en }

const byString = function(o, s) {
  s = s.replace(/\[(\w+)\]/g, ".$1")
  s = s.replace(/^\./, "")
  var a = s.split(".")
  for (var i = 0, n = a.length; i < n; ++i) {
    var k = a[i]
    if (k in o) {
      o = o[k]
    } else {
      return
    }
  }
  return o
}

const formatMessage = ({ id, lang }: { id: string; lang: string }) =>
  byString(texts[lang], id)

export default formatMessage
