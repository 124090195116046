import {
  GatsbyFixedImageProps,
  GatsbyFluidImageProps,
} from "gatsby-source-sanity"

export type SanityImage = {
  asset: {
    fluid: GatsbyFluidImageProps
    fixed: GatsbyFixedImageProps
  }
}

export enum lang {
  en = "en",
  ar = "ar",
}

export enum cta_styles {
  button = "button",
  link = "link",
}

export enum carousel_item_variant {
  primary = "primary",
  secondary = "secondary",
  tertiary = "tertiary",
}

export enum sanity_typename {
  SanityTestimonialsSection = "SanityTestimonialsSection",
  SanityInfoSection = "SanityInfoSection",
  SanityFeaturesSection = "SanityFeaturesSection",
  SanityPromotionSection = "SanityPromotionSection",
  SanityCarouselSection = "SanityCarouselSection",
  SanityBusinessSection = "SanityBusinessSection",
  SanityHeroSection = "SanityHeroSection",
  SanityPricingPlansSection = "SanityPricingPlansSection",
  SanityFaqsSection = "SanityFaqsSection",
  SanityImageFeaturesSection = "SanityImageFeaturesSection",
  SanityPromotionWithImagesSection = "SanityPromotionWithImagesSection",
  SanitySimpleHeroSection = "SanitySimpleHeroSection",
  SanityPostRichSection = "SanityPostRichSection",
}

export enum text_marks {
  strong = "strong",
  em = "em",
  italic = "italic",
  code = "code",
}

export type RichText = {
  text: string
  marks: text_marks[]
  _key: string
  _type: string
}
export type SanityText = {
  style: string
  list: any
  children: RichText[]
}
export type SanityRawText = {
  _key: string
  _type: string
  children: RichText[]
  marksDef: any[]
  style: string
}

export type LangText = {
  en?: string
  ar?: string
}

export type LangContent = {
  en?: SanityRawText[]
  ar?: SanityRawText[]
}

export type SanityPerk = {
  title?: LangText
}

export type SanityRegistrationForm = {
  ctaLink?: string
  ctaText?: LangText
  perks?: SanityPerk[]
}

export type SanityLink = {
  label?: LangText
  href?: string
  style?: string
}

export type SanityLinksGroup = {
  headline?: LangText
  links?: SanityLink[]
}

export type SanitySocialLink = {
  link: string
  image: SanityImage
}

export type SanityCta = {
  label?: LangText
  href?: string
  style?: cta_styles
}

export type SanityFooter = {
  title?: string
  heading?: LangText
  perks?: SanityPerk[]
  registrationForm: SanityRegistrationForm
  linksGroups?: SanityLinksGroup[]
  headline?: LangText
  subheadline?: LangText
  socialLinks: SanitySocialLink[]
}

export type SanitySlug = {
  current: string
}

export type SanityChips = {
  headline?: LangText
  subheadline?: LangText
}

export type SanityBusiness = {
  title: LangText
  category: LangText
  image: SanityImage
  countries: string[]
}

export type SanityPricingFeature = {
  title: LangText
}

export type SanityDescriptionPerk = {
  headline?: LangText
  subheadline?: LangText
  cta?: SanityCta
  image?: SanityImage
}

export type SanityPlanBilling = {
  countryCode: LangText
  monthlyPlanId?: string
  monthlyPrice?: string
  yearlyPlanId?: string
  yearlyPrice?: string
}

export type SanityPlan = {
  mostPopular: Boolean
  headline: LangText
  subheadline: LangText
  discountText: LangText
  features: SanityPricingFeature[]
  cta: SanityCta
  pricingPlanBilling: SanityPlanBilling[]
  paymentMethods: string[]
}

export type SanityFaq = {
  question: LangText
  answer: LangContent
  cta: SanityCta
}

export type SanityFeature = {
  title: LangText
  description: LangText
  image: SanityImage
  cta: SanityCta
}

export type SanityTestimony = {
  reviewerName?: LangText
  reviewerTitle?: LangText
  reviewerImage?: SanityImage
  testimonial?: LangContent
  storeLogo?: string
}

export type SanityTestimonialSection = {
  __typename: sanity_typename.SanityTestimonialsSection
  title?: LangText
  testimonies: SanityTestimony[]
  countriesContent: any
}

export type SanityHero = {
  __typename: sanity_typename.SanityHeroSection
  title?: LangText
  subtitle?: LangText
  video?: string
  comission?: string
  registrationForm?: SanityRegistrationForm
  image?: {
    en?: SanityImage
    ar?: SanityImage
  }
  chips?: SanityChips[]
  countriesContent: any
}

export type SanityInfoSection = {
  __typename: sanity_typename.SanityInfoSection
  title?: LangText
  subtitle?: LangText
  image?: SanityImage
  supportImage?: SanityImage
  featuresList: SanityFeature[]
  countriesContent: any
}

export type SanitySimpleHeroSection = {
  __typename: sanity_typename.SanitySimpleHeroSection
  title?: LangText
  subtitle?: LangText
  cta?: SanityCta
  countriesContent: any
}

export type SanityPostRichSection = {
  __typename: sanity_typename.SanityPostRichSection
  tags: string
  title: string
}

export type SanityCarouselItem = {
  cta?: SanityCta
  image?: SanityImage
  title?: LangText
  subtitle?: LangText
  comingSoon?: boolean
  variant?: carousel_item_variant
}

export type SanityCarouselSection = {
  __typename: sanity_typename.SanityCarouselSection
  title?: LangText
  subtitle?: LangText
  items?: SanityCarouselItem[]
  countriesContent: any
}

export type SanityBusinessSection = {
  __typename: sanity_typename.SanityBusinessSection
  title: LangText
  business: SanityBusiness[]
  countriesContent: any
}

export type SanityPromotion = {
  __typename: sanity_typename.SanityPromotionSection
  inverted?: boolean
  heading?: LangText
  title?: LangText
  slug?: SanitySlug
  description?: LangText
  descriptionPerks: SanityDescriptionPerk[]
  mainCTA?: SanityCta
  registrationForm?: SanityRegistrationForm
  countriesContent: any
}

export type SanityPricingSection = {
  __typename: sanity_typename.SanityPricingPlansSection
  headline: LangText
  subheadline: LangText
  plans: SanityPlan[]
}

export type SanityFaqsSection = {
  __typename: sanity_typename.SanityFaqsSection
  title: LangText
  faqs: SanityFaq[]
  countriesContent: any
}

export type SanityFeaturesSection = {
  __typename: sanity_typename.SanityFeaturesSection
  title: LangText
  features: SanityFeature[]
  countriesContent: any
}

export type SanityPromotionImage = {
  image: SanityImage
  headline: LangText
  subheadline: LangText
  cta: SanityCta
}

export type SanityPromotionWithImagesSection = {
  __typename: sanity_typename.SanityPromotionWithImagesSection
  heading: LangText
  title: LangText
  slug?: SanitySlug
  description: LangText
  variant: carousel_item_variant
  images: SanityPromotionImage[]
  countriesContent: any
}

export type SanityPage = {
  title?: LangText
  slug: SanitySlug
  content: (
    | SanityHero
    | SanitySimpleHeroSection
    | SanityBusinessSection
    | SanityPromotion
    | SanityTestimonialSection
    | SanityPricingSection
    | SanityFaqsSection
    | SanityFeaturesSection
    | SanityCarouselSection
    | SanityPromotionWithImagesSection
    | SanityPostRichSection
  )[]
}

export type SanityNavbar = {
  mainIcon: {
    en: SanityImage
    ar: SanityImage
  }
  links: SanityCta[]
}

export type Option = { icon: any; label: string; value: string }

export type UserDealInfo = {
  name?
  instagram?
}

export type UserContact = {
  mobile?
  email?
}

export type SignUpFormFields = {
  organizationName: string
  instagram?: string
  firstName: string
  lastName: string
  email: string
  mobile: string
  password: string
  country?: string
  isCommissionBased?: boolean
  defaultCountry?: string
}

export type BEPlan = {
  currency: {
    titleAr: string
    titleEn: string
  }
  countryCode: string
  descriptionAr: string
  descriptionEn: string
  discountTextAr: string
  discountTextEn: string
  featuresAr: string[]
  featuresEn: string[]
  paymentMethods: string[]
  id: string
  interval: string
  position: string
  price: string
  titleAr: string
  titleEn: string
  showOnWebsite?: boolean
}

export type BEPlans = {
  plans: BEPlan[]
}
