import React from "react"
import { Link as GatsbyLink } from "gatsby"
import LangContext from "@contexts/lang"

export const Link = ({ to, children, ...props }) => {
  const { lang } = React.useContext(LangContext)
  const computedTo = `/${lang}${to}`

  return (
    <GatsbyLink to={computedTo} {...props}>
      {children}
    </GatsbyLink>
  )
}
