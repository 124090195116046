export const DEFAULT_COUNTRY_CODE = process.env.CLIENT_DEFAULT_COUNTRY_CODE
export const REGISTRATION_URL = process.env.CLIENT_REGISTRATION_URL
export const BE_URL = process.env.CLIENT_BE_URL
export const DASH_URL = process.env.CLIENT_DASH_URL
export const VALIDATION_URL = `${BE_URL}/validate_lead`
export const CONTACT_NUMBER = undefined
export const CALENDLY_URL = process.env.CALENDLY_URL
export const GMAPS_KEY = process.env.CLIENT_GMAPS_KEY
export const LOG_IN_URL = process.env.CLIENT_LOG_IN_URL
export const PIXEL_ID = process.env.PIXEL_ID
export const ADD_HUBSPOT_CONTACT_URL= `${BE_URL}/create_hubspot_contact`
