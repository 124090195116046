import React from "react"
import s from "./Footer.module.scss"
import { SanityLink, SanityLinksGroup } from "@utils/types"
import { Link } from "@components/common/LangLink"
import LangContext from "@contexts/lang"

const CustomLink: React.FC<{ link: SanityLink }> = ({ link }) => {
  const { lang } = React.useContext(LangContext)

  if (link.href?.startsWith("http") || link.href?.startsWith("www")) {
    return (
      <a className={s.customLink} href={link.href}>
        {link.label[lang]}
      </a>
    )
  }

  return (
    <Link className={s.customLink} to={link.href}>
      {link.label[lang]}
    </Link>
  )
}

const LinksGroup: React.FC<{ linksGroup: SanityLinksGroup }> = ({
  linksGroup,
}) => {
  const { lang } = React.useContext(LangContext)
  const { headline, links } = linksGroup

  return (
    <div className={s.linksGroup}>
      <p className={s.headline}>{headline && headline[lang]}</p>
      {links && (
        <div className={s.customLinks}>
          {links.map((link, i) => (
            <CustomLink link={link} key={i} />
          ))}
        </div>
      )}
    </div>
  )
}
export default LinksGroup
