import React, { SetStateAction, Dispatch } from "react"
import { Link } from "@components/common/LangLink"
import LangContext from "@contexts/lang"
import s from "./Header.module.scss"
import { SanityCta } from "@utils/types"

const HeaderOption: React.FC<{
  option: SanityCta
  className?: string
  utmText?: null
  setIsVisible?: Dispatch<SetStateAction<boolean>>
}> = ({ option, className, utmText, setIsVisible }) => {
  const { lang } = React.useContext(LangContext);
  const urlParams = typeof window !== typeof undefined ? new URLSearchParams(window.location.search) : undefined;
  const countryParam = urlParams?.get('country');
  const querParams = countryParam ? `?country=${countryParam}}&${utmText}` : `?${utmText}`
  const zydaUrl = `${option.href}${querParams}`;
  if (option.href.startsWith("http")) {
    return (
      <a className={`${s.link} ${className}`} href={utmText ? zydaUrl : option.href} lang={lang} target={option.target}>
        {option.label[lang]}
      </a>
    )
  }

  return (
    <Link
      className={`${s.link} ${className}`}
      to={utmText ? zydaUrl : option.href}
      onClick={setIsVisible ? () => setIsVisible(false) : () => {}}
    >
      {option.label[lang]}
    </Link>
  )
}

export default HeaderOption
