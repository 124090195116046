import React, { FC } from "react"
import { navigate } from "gatsby"
import { useLocation } from "@reach/router"
import { lang as language } from "@utils/types"
import LangContext from "@contexts/lang"
import s from "./Header.module.scss"

const LanguageButton: FC<{}> = ({}) => {
  const { lang, setLang } = React.useContext(LangContext)
  const location = useLocation()

  const handleClick = () => {
    const newLang = lang === language.en ? language.ar : language.en
    setLang(newLang)
    const [_, currentLang, ...path] = location.pathname.split("/")
    navigate(`/${newLang}/${path.join("/")}`)
  }

  return (
    <div className={s.circledBorder}>
      <button className={s.selectButton} onClick={handleClick}>
        <span>{lang === "en" ? "AR" : "EN"}</span>
      </button>
    </div>
  )
}

export default LanguageButton
