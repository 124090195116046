import React from "react"
import s from "./Footer.module.scss"
import { useStaticQuery, graphql } from "gatsby"
import { SanityFooter } from "@utils/types"
import LangContext from "@contexts/lang"
import Perk from "../../../globals/perks/perk"
import LinksGroup from "./LinkGroup"
import SocialLink from "./SocialLink"
import BackgroundImage from "gatsby-background-image"
import FreeTrialForm from "@components/common/FreeTrialForm"
import FooterAddress from "@components/Layout/Components/Footer/FooterAddress/FooterAddress"
import formatMessage from "@utils/texts"

const Footer: React.FC<{ lite?: boolean }> = ({ lite = false }) => {
  const { lang } = React.useContext(LangContext)

  const { sanityFooter, footerImage } = useStaticQuery<{
    sanityFooter: SanityFooter
    footerImage
  }>(query)
  if (!sanityFooter) return <></>

  const {
    heading,
    subheadline,
    perks,
    registrationForm,
    linksGroups,
    headline,
    socialLinks,
  } = sanityFooter

  return (
    <div>
      <div className={`${s.footerWrapper} ${lang === "ar" && s.ibmPlex} ${lite && s.liteFooterBg}`}>
        <div className={`${s.formContainer} ${lite && s.liteBg}`}>
          <div className={`${s.registrationForm} ${lite && s.liteColor}`}>
            <div className={`${s.texts} ${lite && s.white}`}>
              {lite ? (
                <div>
                  <h3>{formatMessage({ id: "getOrders", lang })}</h3>
                  <p>{formatMessage({ id: "commission", lang })}</p>
                </div>
              ) : (
                heading && <h3>{heading[lang]}</h3>
              )}
              {perks?.length > 0 && (
                <div className={s.perks}>
                  {perks?.map((perk, i) => (
                    <Perk className={s.perk} key={i} perk={perk} />
                  ))}
                </div>
              )}
            </div>
            <FreeTrialForm registrationForm={registrationForm} addLead />
          </div>
        </div>
        <div className={`${s.footer} ${lite && s.liteFooter}`}>
          {linksGroups && (
            <div className={s.linksGroups}>
              {linksGroups.map((linksGroup, i) => (
                <LinksGroup linksGroup={linksGroup} key={i} />
              ))}
            </div>
          )}
          <FooterAddress />
          <div className={s.separator}></div>
          <div className={s.noteLinks}>
            <h5 className={s.note}>{headline[lang]}</h5>
            <div className={s.socialLinks}>
              {socialLinks.map((socialLink, i) => (
                <SocialLink socialLink={socialLink} key={i} />
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Footer

const query = graphql`
  {
    sanityFooter {
      heading {
        en
        ar
      }
      subheadline {
        en
        ar
      }
      perks {
        title {
          en
          ar
        }
      }
      registrationForm {
        ctaText {
          en
          ar
        }
        ctaLink
      }
      headline {
        en
        ar
      }
      socialLinks {
        image {
          asset {
            fixed(width: 40) {
              ...GatsbySanityImageFixed
            }
          }
        }
        link
      }
      linksGroups {
        headline {
          en
          ar
        }
        links {
          label {
            en
            ar
          }
          href
          style
        }
      }
    }
    footerImage: file(relativePath: { eq: "footer-image.png" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`
