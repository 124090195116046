// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-components-community-page-article-page-community-article-tsx": () => import("./../../../src/components/communityPage/articlePage/CommunityArticle.tsx" /* webpackChunkName: "component---src-components-community-page-article-page-community-article-tsx" */),
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-community-tsx": () => import("./../../../src/pages/community.tsx" /* webpackChunkName: "component---src-pages-community-tsx" */),
  "component---src-pages-features-tsx": () => import("./../../../src/pages/features.tsx" /* webpackChunkName: "component---src-pages-features-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-lite-tsx": () => import("./../../../src/pages/lite.tsx" /* webpackChunkName: "component---src-pages-lite-tsx" */),
  "component---src-pages-pricing-tsx": () => import("./../../../src/pages/pricing.tsx" /* webpackChunkName: "component---src-pages-pricing-tsx" */),
  "component---src-pages-register-index-tsx": () => import("./../../../src/pages/register/index.tsx" /* webpackChunkName: "component---src-pages-register-index-tsx" */),
  "component---src-pages-register-payment-index-tsx": () => import("./../../../src/pages/register/payment/index.tsx" /* webpackChunkName: "component---src-pages-register-payment-index-tsx" */),
  "component---src-pages-register-payment-process-tsx": () => import("./../../../src/pages/register/payment/process.tsx" /* webpackChunkName: "component---src-pages-register-payment-process-tsx" */),
  "component---src-pages-thanks-tsx": () => import("./../../../src/pages/thanks.tsx" /* webpackChunkName: "component---src-pages-thanks-tsx" */)
}

