import React from "react"
import LangContext from "@contexts/lang";
import s from "./FooterAddress.module.scss"

const FooterAddress = () => {
    const { lang, setLang } = React.useContext(LangContext)
    return (
        <div className={s.flexContainer}>
            <div className={s.addressContainer}>
                <h5>{lang === "en" ? "Zyda Information Systems" : "زيدا لنظم المعلومات"}</h5>
                <h5>{lang === "en" ? "Serving mavericks." : "حلول حديثة للتجارة الالكترونية"}</h5>
                <a className={s.link} href={"mailto:hello@zyda.com"} target="_blank" rel="noopener noreferrer">hello@zyda.com</a>
            </div>
            <div className={s.addressContainer}>
                <h5>{lang === "en" ? "Kuwait Office" : "الكويت"}</h5>
                <h5>{lang === "en" ? "Floor 18, Fahad Al-salem," : "شارع فهد السالم ,الدور 18"}</h5>
                <h5>{lang === "en" ? "Panasonic Tower," : "برج باناسونيك"}</h5>
                <h5>{lang === "en" ? "Kuwait City." : " العاصمة."}</h5>
            </div>
            <div className={s.addressContainer}>
                <h5>{lang === "en" ? "Egypt Office" : "مصر"}</h5>
                <h5>{lang === "en" ? "Building 11," : "مبنى 11"}</h5>
                <h5>{lang === "en" ? "Street 84, Sarayat el Maadi" : "شارع 84، سرايات المعادي،"}</h5>
                <h5>{lang === "en" ? "Cairo." : "القاهرة."}</h5>
            </div>
        </div>
    )
}

export default FooterAddress
