import React, { useEffect } from "react"
import { graphql, useStaticQuery } from "gatsby"
import { Link } from "@components/common/LangLink"
import { GiHamburgerMenu } from "react-icons/gi"
import { GrClose } from "react-icons/gr"
import Img from "gatsby-image"
import LangContext from "@contexts/lang"
import { cta_styles, SanityNavbar } from "@utils/types"
import HeaderOption from "./HeaderOption"
import LanguageButton from "./LanguageButton"
import s from "./MobileHeader.module.scss"
import { openPopupWidget } from "react-calendly"
import { CALENDLY_URL, LOG_IN_URL } from "@utils/constants"
import { BE_URL, DEFAULT_COUNTRY_CODE } from "@utils/constants"
import axios from "axios"
import useReadCookie from "@hooks/useReadCookie"
import { v4 as uuidv4 } from "uuid"
import publicIp from "public-ip"
import trackFacebookEvent from "@utils/trackFacebookEvent"

const MobileHeader: React.FC<{}> = () => {
  const { lang } = React.useContext(LangContext)
  const [isVisible, setIsVisible] = React.useState(false)
  const {
    navbar: { mainIcon, links },
  } = useStaticQuery<{ navbar: SanityNavbar }>(query)

  const navVisible = isVisible ? s.dBlock : s.dNone
  const { countryCode } = React.useContext(LangContext)

  const logIn = {
    label: {
      en: "Login",
      ar: "تسجيل الدخول",
    },
    href: `${LOG_IN_URL}?lang=${lang}`,
    style: cta_styles.link,
  }

  const careers = {
    href: "https://careers.zyda.com/?lng=en",
    label: { en: 'Careers', ar: 'وظائف' },
    style: cta_styles.button,
    target: "_blank"
  }

  if (typeof document !== "undefined") {
    var banner = document.querySelector(".Banner-module--banner--2bS58")
  }
  if (isVisible) {
    banner?.classList?.add(`${s.topMargin}`)
  } else {
    banner?.classList?.remove(`${s.topMargin}`)
  }

  return (
    <>
      <nav className={s.navbar}>
        <Link
          to="/"
          onClick={() =>
            trackFacebookEvent("PageView", {
              country: countryCode || DEFAULT_COUNTRY_CODE,
            })
          }
        >
          <Img className={s.mainLogo} fluid={mainIcon?.[lang].asset?.fluid} />
        </Link>
        <div className={s.buttonsContainer}>
          <LanguageButton />
          <div onClick={() => setIsVisible(!isVisible)}>
            {isVisible ? (
              <GrClose className={s.deleteIcon} />
            ) : (
              <div className={s.burgerContent}>
                <GiHamburgerMenu className={s.barsIcon} />
              </div>
            )}
          </div>
        </div>
      </nav>
      <div className={`${s.nav} ${navVisible}`}>
        {links.map((option, index) => (
          <div
            key={index}
            onClick={() =>
              trackFacebookEvent("PageView", {
                country: countryCode || DEFAULT_COUNTRY_CODE,
              })
            }
          >
            <HeaderOption
              className={s.navItem}
              setIsVisible={setIsVisible}
              key={index}
              option={option}
            />
          </div>
        ))}
        <HeaderOption option={careers} className={s.navItem} />
        <HeaderOption option={logIn} className={s.navItem} />
        <a href="https://calendly.com/d/cm6-9js-nh5/increase-revenue-with-online-ordering">
          <button
            className={`demoGoogleTag ${s.demoButton} `}
            id="book-demo-button"
          >
            {lang === "en" ? "Book a Demo" : "حجز موعد"}
          </button>
        </a>
      </div>
    </>
  )
}

export default MobileHeader

const query = graphql`
  {
    navbar: sanityNavbar {
      mainIcon {
        en {
          asset {
            fluid {
              ...GatsbySanityImageFluid
            }
          }
        }
        ar {
          asset {
            fluid {
              ...GatsbySanityImageFluid
            }
          }
        }
      }
      links {
        label {
          en
          ar
        }
        href
        style
      }
    }
  }
`
