/* eslint-disable no-console */
import React from "react"
import s from "./Banner.module.scss"
import LangContext from "@contexts/lang"

const Banner: React.FC = () => {
  const { lang } = React.useContext(LangContext)
  const bannerContent = [
    "Breaking News: You can now have your very own app ready in 2 week",
    "أخبار عاجلة: يمكنك الآن إمتلاك تطبيقك الخاص خلال أسبوعين",
  ]
  return (
    <div>
      <div className={s.banner}>
        <div className={s.bannerContent}>
          <div className={s.bannerText}>
            <span>
              🎉 {bannerContent[lang === "en" ? 0 : 1]}
              <a
                className="pageLink"
                target="_blank"
                href="https://calendly.com/d/cm6-9js-nh5/increase-revenue-with-online-ordering"
              >
                {lang === "en" ? "  Learn More  " : "  اعرف المزيد  "}
              </a>
            </span>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Banner
