/* eslint-disable @typescript-eslint/no-unused-vars */
import "../../styles/index.scss"
import React, { FC } from "react"
import { navigate } from "gatsby"
import LangContext from "@contexts/lang"
import useResizeWidth from "@hooks/useResizeWidth"
import s from "./Layout.module.scss"
import Header from "../Layout/Components/Header/Header"
import Footer from "./Components/Footer"
import MobileHeader from "./Components/Header/MobileHeader"
import { lang } from "../../utils/types"
import useSearchParams from "@hooks/useSearchParams"
import { DEFAULT_COUNTRY_CODE } from "@utils/constants"
import useMounted from "@hooks/useMounted"
import Banner from "./Components/Banner/Banner"
import Modal from "react-modal"
import zydaIcon from "../../images/zlogo2.png"
import { Link } from "@components/common/LangLink"
import formatMessage from "@utils/texts"

Modal.setAppElement("#___gatsby")

const Layout: FC<{ location }> = ({ children, location }) => {
  const hasMounted = useMounted()
  const [currentCountry, setCurrentCountry] = React.useState(
    DEFAULT_COUNTRY_CODE
  )
  const [currentLang, setCurrentLang] = React.useState(lang.ar)
  const { width } = useResizeWidth()
  const { country } = useSearchParams(location)
  const [modalIsOpen, setIsOpen] = React.useState(true)

  if (typeof window !== "undefined") {
    var pathname = window.location.pathname,
      part = pathname.substr(pathname.lastIndexOf("/") + 1)
  }

  function openModal() {
    setIsOpen(true)
  }
  function closeModal() {
    setIsOpen(false)
  }
  const setCountryCode = (country: string) => {
    setCurrentCountry(country)
    if (hasMounted) {
      localStorage.setItem("zyda_country", country)
    }
  }

  let browserStorage = typeof localStorage === "undefined" ? null : localStorage
  const countryCode = browserStorage?.getItem("zyda_country")

  React.useEffect(() => {
    if (hasMounted && location.pathname.startsWith("/en/")) {
      setCurrentLang(lang.en)
    }
  }, [hasMounted])

  React.useEffect(() => {
    if (country && country === "EG") {
      setCurrentLang(lang.en)
      const [_, currentLang, ...path] = location.pathname.split("/")
      navigate(`/${lang.en}/${path.join("/")}${location.search}`)
    }
  }, [])

  React.useEffect(() => {
    if (location.pathname.includes("/lite")) {
      navigate(`/`)
    }
  }, [location.pathname])

  React.useEffect(() => {
    if (country) {
      return setCountryCode(country)
    } else if (countryCode) {
      return setCountryCode(countryCode)
    } else {
      return setCountryCode(DEFAULT_COUNTRY_CODE)
    }
  }, [])

  const isMobile = hasMounted && width <= 1110
  const customStyles = {
    content: {
      top: "50%",
      left: "50%",
      right: "auto",
      bottom: "auto",
      transform: "translate(-50%, -50%)",
      padding: "0",
    },
  }
  return (
    <LangContext.Provider
      value={{
        lang: currentLang,
        setLang: setCurrentLang,
        countryCode: currentCountry,
        setCountryCode,
      }}
    >
      <main
        className={[
          "mainContainer",
          currentLang === "ar" && s.arabicFontOverride,
          part === "lite" && s.liteBg,
        ].join(" ")}
      >
        <Banner />
        {/* <Modal
          isOpen={modalIsOpen}
          onRequestClose={closeModal}
          style={customStyles}
          contentLabel="Lite Modal"
          className={s.modal}
          preventScroll={true}
        >
          <button className={s.closeButton} onClick={closeModal}>
            {formatMessage({ id: "dismiss", lang: currentLang })}
          </button>
          <img className={s.zydaIcon} src={zydaIcon}></img>
          <div className={s.modalContainer}>
            <div className={s.plus}>
              <div className={s.imgContainer}>
                <h3 className={s.bold}>
                  {formatMessage({ id: "allBusinesses", lang: currentLang })}
                </h3>
              </div>
              <p className={s.details}>
                {formatMessage({ id: "subscriptionBased", lang: currentLang })}
              </p>
              <button className={s.liteButton} onClick={closeModal}>
                {formatMessage({ id: "knowMore", lang: currentLang })}
              </button>
            </div>
            <div className={s.lite}>
              <div className={s.imgContainer}>
                <h3 className={s.bold}>
                  {formatMessage({ id: "lite", lang: currentLang })}
                </h3>
              </div>
              <p className={s.details}>
                {formatMessage({ id: "commissionBased", lang: currentLang })}
              </p>
              <Link to="/lite" className={s.plusButton} onClick={closeModal}>
                {formatMessage({ id: "signUp", lang: currentLang })}
              </Link>
            </div>
          </div>
        </Modal> */}

        {isMobile ? <MobileHeader /> : <Header />}
        {children}
        <Footer lite={part === "lite" && true} />
      </main>
    </LangContext.Provider>
  )
}

export default Layout
